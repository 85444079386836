.actions-menu {
  cursor: pointer;

  &:hover {
    color: var(--blue-base-components);
  }
}

.mat-menu-item {
  line-height: 32px !important;
  height: 32px !important;
  transition: all 0.5s ease-out;
}

.mat-menu-item:hover {
  background: var(--blue-base-components) !important;
  color: var(--primary-color-600);
}

.mat-menu-panel {
  min-height: 30px !important;
}

$background-color_1: #f6f8f9;

.mat-mdc-menu-panel.user-menu {
  background-color: $background-color_1;
}
.user-menu {
  button {
    &:hover {
      background: #d9d9d9;
    }
  }
}

